@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Alexandria';
  src: url('../public/fonts/Alexandria-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'Alexandria';
}

:root {
  --doc-height: 100%;
}

@media screen and (max-width: 768px) {
  .App {
    width: 100vw;
    /* height: 100vh;
    height: var(--doc-height); */
    max-width: 100%;
    overflow-x: hidden;
  }

  .layout {
    /* height: var(--doc-height); */
    height: 100vh;
    height: 100dvh;
  }

}

.slick-prev:before,
.slick-next:before {
  color: #DC5F38 !important;
}

.aiResponse ul {
  padding-left: 28px;
  padding-right: 28px;
  list-style-type: circle;
}

.aiResponse ol {
  padding-left: 28px;
  padding-right: 28px;
  list-style-type: decimal;
}

.aiResponse h1 {
  font-weight: bold;
  font-size: x-large;
  padding-bottom: 12px;
}

.aiResponse p {
  margin-bottom: 14px;
  line-height: 1.8;
}

.aiResponse a {
  color: blue;
  margin-bottom: 14px;
  line-height: 1.8;
}

.aiResponse a :hover {
  color: #FA6D34;
  margin-bottom: 14px;
  line-height: 1.8;
}

.aiResponse h2 {
  font-weight: bold;
  font-size: large;
  padding-bottom: 8px;
}

.aiResponse h3 {
  font-weight: bold;
  font-size: medium;
  padding-bottom: 6px;
}

.aiResponse p,
.aiResponse li {
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  text-rendering: optimizeLegibility;
  font-variant-numeric: proportional-nums;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* To remove default toggle password button*/
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  display: none !important;
}

/* To remove default incrementation number button*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #11111120;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #737373;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #737373;
}


/* Source skeleton */
.skeleton-container {
  display: flex;
  justify-content: space-between;
}

.skeleton-item {
  background-color: #dadada;
  margin-right: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.skeleton-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right,
      transparent 0%,
      rgba(190, 190, 190, 0.4) 20%,
      transparent 100%);
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}